import React, { Fragment } from 'react';
const Banner = () => {
  return (
    <Fragment>
      {/* ======= Hero Section ======= */}
      <section id="hero">
          <div className="hero-container">
              <div id="heroCarousel" data-bs-interval="5000" className="carousel slide carousel-fade" data-bs-ride="carousel">

                  <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

                  <div className="carousel-inner" role="listbox">

                      {/* Slide 1 */}
                      <div className="carousel-item active" style={{backgroundImage: 'url(./assets/img/slide/slide1.jpg)' }}>
                          <div className="carousel-container">
                              <div className="carousel-content">
                                  <h2 className="animate__animated animate__fadeInDown"><span>An Loc</span> Restaurant</h2>
                                  {/* <p className="animate__animated animate__fadeInUp">Ở các mẫu bài viết quảng cáo về nhà hàng, khi viết cần tập trung vào những gì khách hàng nhận được, điều này sẽ khiến mọi người thích thú hơn và đặt lịch ghé đến khi nhà hàng vẫn còn áp dụng.</p>
                                  <p className="animate__animated animate__fadeInUp">Ở các mẫu bài viết quảng cáo về nhà hàng, khi viết cần tập trung vào những gì khách hàng nhận được, điều này sẽ khiến mọi người thích thú hơn và đặt lịch ghé đến khi nhà hàng vẫn còn áp dụng.<br/>
                                  - Chủ đề giới thiệu về kinh doanh ẩm thực<br/>
                                  - Đối tượng khách hàng<br/>
                                  - Ngôn từ gây thèm và khách tò mò muốn đến<br/>
                                  </p> */}
                                  <p className="animate__animated animate__fadeInUp">
                                  Tận hưởng cảm giác và hương vị đến từ món ăn ngay khi bạn đến với Nhà Hàng với danh sách Menu món đa dạng. Thoát khỏi sự nhàm chán vây quanh "Ăn Gì Bây Giờ" chúng tôi hoạt động Sáng - Trưa - Tối đem lại sự lựa chọn hài lòng nhất cho Quý Khách<br/>
                                  </p>
                                  <div>
                                      <a href="#menu" className="btn-menu animate__animated animate__fadeInUp scrollto">Menu</a>
                                      <a href="#book-a-table" className="btn-book animate__animated animate__fadeInUp scrollto">Đặt Bàn Ngay</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      {/* Slide 2 */}
                      <div className="carousel-item" style={{ 'backgroundImage': 'url(assets/img/slide/slide2.png)' }}>
                          <div className="carousel-container">
                              <div className="carousel-content">
                                  <h2 className="animate__animated animate__fadeInDown"><span>Chất Lượng</span> Giá Phù Hợp</h2>
                                  <p className="animate__animated animate__fadeInUp">Ăn món gì - Thời điểm nào và ăn với ai tại An Lộc. Chúng tôi tận tâm phục vụ các món ăn từ khâu lựa chọn nguyên liệu đến chế biến để mang lại sự hài lòng cho quý khách</p>
                                  <div>
                                      <a href="#menu" className="btn-menu animate__animated animate__fadeInUp scrollto">Menu</a>
                                      <a href="#book-a-table" className="btn-book animate__animated animate__fadeInUp scrollto">Đặt Bàn Ngay</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      {/* Slide 3 */}
                      <div className="carousel-item" style={{ 'backgroundImage': 'url(assets/img/slide/slide3.png)' }}>
                          <div className="carousel-container">
                              <div className="carousel-content">
                                  <h2 className="animate__animated animate__fadeInDown"><span>Ăn An Lành</span> Sống An Lạc</h2>
                                  <p className="animate__animated animate__fadeInUp">Phở là món ăn truyền thống cũng là một trong những món ăn quốc hồn quốc túy của việt nam. Nó cũng đã được khẳng định trên trường quốc tế đó là một trong những cơ sở tạo nên Menu Phở An Lộc.</p>
                                  <div>
                                      <a href="#menu" className="btn-menu animate__animated animate__fadeInUp scrollto">Menu</a>
                                      <a href="#book-a-table" className="btn-book animate__animated animate__fadeInUp scrollto">Đặt Bàn Ngay</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>

                  <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                  </a>

                  <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
                      <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                  </a>
              </div>
          </div>
      </section>{/* End Hero */}
    </Fragment>
  );
};

export default Banner;
