import React, { Fragment } from 'react';
const Footer = () => {
  return (
    <Fragment>
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="container">
          <h3>An Lộc</h3>
          <p>Khởi nguồn cảm hứng và đam mê - Cùng chúng tôi trải nghiệm văn hoá Ẩm Thực Truyền  Thống.</p>
          <div className="social-links">
            <a href="/" className="twitter"><i className="bx bxl-twitter"></i></a>
            <a href="/" className="facebook"><i className="bx bxl-facebook"></i></a>
            <a href="/" className="instagram"><i className="bx bxl-instagram"></i></a>
            {/* <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a> */}
          </div>
          <div className="copyright">
            &copy; Copyright <strong><span>ANLOC</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
          </div>
        </div>
      </footer>{/* End Footer */}
    </Fragment>
  );
};

export default Footer;
