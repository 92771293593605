import React, { Fragment } from "react";

function Events() {
    return (
        <Fragment>

            {/* ======= Events Section ======= */}
            <section id="events" className="events">
                <div className="container">

                    <div className="section-title">
                        <h2>Tổ chức <span>Sự Kiện</span> tại nhà hàng chúng tôi</h2>
                    </div>

                    <div className="events-slider swiper">
                        <div className="swiper-wrapper">

                            <div className="swiper-slide">
                                <div className="row event-item">
                                    <div className="col-lg-6">
                                        <img src="assets/img/event-birthday.jpg" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                                        <h3>Tiệc Sinh Nhật</h3>
                                        <div className="price">
                                            <p><span>199K</span></p>
                                        </div>
                                        <p className="fst-italic">
                                            Bí quyết thành công là ăn những gì bạn thích và hãy để mặc chúng quyết đấu trong bụng của bạn.
                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> Còn tình cảm nào hơn tình yêu chân thành giành cho bữa ăn của bạn.</li>
                                            <li><i className="bi bi-check-circle"></i> Nếu không ăn uống tử thế thì làm sao suy nghĩ, yêu thương tử tế được.</li>
                                            <li><i className="bi bi-check-circle"></i> Bữa tiệc của bạn hãy cùng chúng tôi chia sẻ khoảnh khắc khó quên.</li>
                                        </ul>
                                        <p>
                                            Có 3 con đường đến với bao tử của bạn. Một là mua thức ăn, hai là nấu những gì bạn thích và ba là hãy đến với những món ăn của chúng tôi.
                                            Hãy cảm nhận lựa chọn của chúng tôi là một trong các cầu nối về giá trị của sự "chân thành - tử tế và lòng biết ơn" của cuộc sống.
                                        </p>
                                    </div>
                                </div>
                            </div>{/* End testimonial item */}

                            <div className="swiper-slide">
                                <div className="row event-item">
                                    <div className="col-lg-6">
                                        <img src="assets/img/event-private.jpg" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                                        <h3>Tiệc Gia Đình</h3>
                                        <div className="price">
                                            <p><span>199K</span></p>
                                        </div>
                                        <p className="fst-italic">
                                            Không gian ấm cúng, các thành viên sum họp bên bàn tiệc để tăng sự gắn kết và chia sẻ tình yêu thương.
                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> Còn tình cảm nào hơn tình yêu chân thành giành cho bữa ăn của bạn.</li>
                                            <li><i className="bi bi-check-circle"></i> Nếu không ăn uống tử thế thì làm sao suy nghĩ, yêu thương tử tế được.</li>
                                            <li><i className="bi bi-check-circle"></i> Bữa tiệc của bạn hãy cùng chúng tôi chia sẻ khoảnh khắc khó quên.</li>
                                        </ul>
                                        <p>
                                            Có 3 con đường đến với bao tử của bạn. Một là mua thức ăn, hai là nấu những gì bạn thích và ba là hãy đến với những món ăn của chúng tôi.
                                            Hãy cảm nhận lựa chọn của chúng tôi là một trong các cầu nối về giá trị của sự "chân thành - tử tế và lòng biết ơn" của cuộc sống.
                                        </p>
                                    </div>
                                </div>
                            </div>{/* End testimonial item */}

                            <div className="swiper-slide">
                                <div className="row event-item">
                                    <div className="col-lg-6">
                                        <img src="assets/img/event-custom.jpg" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                                        <h3>Tiếp Khách Hàng</h3>
                                        <div className="price">
                                            <p><span>199K</span></p>
                                        </div>
                                        <p className="fst-italic">
                                            Bối cảnh đa dạng để tạo nên sự trọn vẹn trong bữa tiệc theo cách bạn muốn.
                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> Không gian sang trọng tạo nên sự hoàn hảo đối với khách hàng của bạn.</li>
                                            <li><i className="bi bi-check-circle"></i> không gian mở tạo nên những khoảnh khắc ấn tượng cùng đồng nghiệp.</li>
                                            <li><i className="bi bi-check-circle"></i> Cùng chúng tôi chia sẻ khoảnh khắc khó quên trong bữa tiệc của bạn.</li>
                                        </ul>
                                        <p>
                                            Có 3 con đường đến với bao tử của bạn. Một là mua thức ăn, hai là nấu những gì bạn thích và ba là hãy đến với những món ăn của chúng tôi.
                                            Hãy cảm nhận lựa chọn của chúng tôi là một trong các cầu nối về giá trị của sự "chân thành - tử tế và lòng biết ơn" của cuộc sống.
                                        </p>
                                    </div>
                                </div>
                            </div>{/* End testimonial item */}

                        </div>
                        <div className="swiper-pagination"></div>
                    </div>

                </div>
            </section>{/* End Events Section */}
        </Fragment>
    )
}

export default Events;