import React, { Fragment } from 'react';
const FindUs = () => {
  return (
    <Fragment>
      <section id="contact" className="contact">
        <div className="container">

          <div className="section-title">
            <h2><span>Liên Hệ</span> Nhà Hàng</h2>
            <p>Toạ lạc tại khu vực đông dân cư, nhiều cao ốc và khu mua sắm sầm uất kết hợp các tiện ích quy hoạch hiện đại</p>
          </div>
        </div>

        <div className="map">
          {/* <iframe title="contactus" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" width="100%" height="350px" frameBorder="0" style={{border:0}} />           */}
          <iframe title="contactus" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Lynh Cafe, Long Hau&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="350px" frameBorder="0" style={{border:0}} />
        </div>

        <div className="container mt-5">

          <div className="info-wrap">
            <div className="row">
              <div className="col-lg-3 col-md-6 info">
                <i className="bi bi-geo-alt"></i>
                <h4>Địa Điểm:</h4>
                <p>08 - Song Hành<br />P.An Phúú, Quận 2, TP.HCM</p>
              </div>

              <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                <i className="bi bi-clock"></i>
                <h4>Giờ Mở Cửa:</h4>
                <p>Monday-Sunday:<br />06:00-10:00 & 16:00-22:00</p>
              </div>

              <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>pho@anloc.com<br />contact@anloc.com</p>
              </div>

              <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                <i className="bi bi-phone"></i>
                <h4>Phone:</h4>
                <p>+84-0906789789<br />+84-0906789789</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Fragment>
  );
};

export default FindUs;