import React, { Fragment } from 'react';
const Gallery = () => {
  return (
    <Fragment>
      <section id="gallery" className="gallery">
        <div className="container-fluid">

          <div className="section-title">
            <h2>Cảm nhận Nhà Hàng qua <span>Hình Ảnh</span></h2>
            <p>Thiết kế bối cảnh phù hợp với không gian phù hợp với nhu cầu tổ chức hội nhóm, sinh nhật, liên hoan. Trọn vẹn cảm xúc và tận hưởng cảm giác dưới sự khao khát của các thiên sứ ẩm thực.</p>
          </div>

          <div className="row g-0">

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href="assets/img/gallery/gallery-1.jpg" className="gallery-lightbox">
                  <img src="assets/img/gallery/gallery-1.jpg" alt="" className="img-fluid" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href="assets/img/gallery/gallery-2.jpg" className="gallery-lightbox">
                  <img src="assets/img/gallery/gallery-2.jpg" alt="" className="img-fluid" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href="assets/img/gallery/gallery-3.jpg" className="gallery-lightbox">
                  <img src="assets/img/gallery/gallery-3.jpg" alt="" className="img-fluid" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href="assets/img/gallery/gallery-4.jpg" className="gallery-lightbox">
                  <img src="assets/img/gallery/gallery-4.jpg" alt="" className="img-fluid" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href="assets/img/gallery/gallery-5.jpg" className="gallery-lightbox">
                  <img src="assets/img/gallery/gallery-5.jpg" alt="" className="img-fluid" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href="assets/img/gallery/gallery-6.jpg" className="gallery-lightbox">
                  <img src="assets/img/gallery/gallery-6.jpg" alt="" className="img-fluid" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href="assets/img/gallery/gallery-7.jpg" className="gallery-lightbox">
                  <img src="assets/img/gallery/gallery-7.jpg" alt="" className="img-fluid" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href="assets/img/gallery/gallery-8.jpg" className="gallery-lightbox">
                  <img src="assets/img/gallery/gallery-8.jpg" alt="" className="img-fluid" />
                </a>
              </div>
            </div>

          </div>

        </div>
      </section>
    </Fragment>
  );
};

export default Gallery;