import React, { Fragment } from 'react';
const Menu = () => {
    return (
        <Fragment>
            {/* ======= Menu Section ======= */}
            <section id="menu" className="menu">
                <div className="container">

                    <div className="section-title">
                        <h2>Tận hưởng món <span>NGON</span> tại nhà hàng</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <ul id="menu-flters">
                                <li data-filter="*" className="filter-active">Tất Cả</li>
                                <li data-filter="*">Buổi Sáng</li>
                                <li data-filter="*">Buổi Tối</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row menu-container">

                        <div className="col-lg-6 menu-item filter-starters">
                            <div className="menu-content">
                                <a href="#">Phở Tái</a><span>69.000</span>
                            </div>
                            <div className="menu-ingredients">
                            Ngon theo một cách rất riêng
                            </div>
                        </div>

                        <div className="col-lg-6 menu-item filter-starters">
                            <div className="menu-content">
                                <a href="#">Phở Nạm</a><span>69.000</span>
                            </div>
                            <div className="menu-ingredients">
                                Thanh từ nước dùng và ngọt từ nạm bò luộc chín
                            </div>
                        </div>

                        <div className="col-lg-6 menu-item filter-starters">
                            <div className="menu-content">
                                <a href="#">Phở Tái Nạm</a><span>69.000</span>
                            </div>
                            <div className="menu-ingredients">
                            Thanh từ nước dùng và cung cấp dinh dưỡng từ bò tái và bò nạm
                            </div>
                        </div>

                        <div className="col-lg-6 menu-item filter-salads">
                            <div className="menu-content">
                                <a href="#">Phở Nạm Gân</a><span>69.000</span>
                            </div>
                            <div className="menu-ingredients">
                            Ngọt thanh từ ngước dùng sự kích thích khó cưỡng từ gân bò
                            </div>
                        </div>

                        <div className="col-lg-6 menu-item filter-salads">
                            <div className="menu-content">
                                <a href="#">Phở Đặc Biệt</a><span>79.000</span>
                            </div>
                            <div className="menu-ingredients">
                                Sự hòa quyện đầy đủ nguyên liệu tạo nên một hương vị khó cưỡng
                            </div>
                        </div>

                        <div className="col-lg-6 menu-item filter-salads">
                            <div className="menu-content">
                                <a href="#">Tái Thêm</a><span>40.000</span>
                            </div>
                            <div className="menu-ingredients">
                                Thoải mãi tận hưởng theo cách của bạn
                            </div>
                        </div>
                    </div>
                </div>
            </section>{/* End Menu Section */}

        </Fragment>
    );
};

export default Menu;