import React, { Fragment } from 'react';
const AboutUs = () => {
  return (
    <Fragment>
      {/* ======= About Us ======= */}
      <section id="about" className="about">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 align-items-stretch video-box" style={{backgroundImage: 'url(./assets/img/about.jpg)'}}>
              <a href="https://youtu.be/8_UZ9lqD9hY" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
            </div>
            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
              <div className="content">
                <h3>Chăm sóc sức khoẻ của bạn là <strong>SỨ MỆNH CỦA AN LỘC</strong></h3>
                <p>
                Vượt ra khỏi giới hạn “ăn no mặc ấm” để đạt được sự “ăn ngon mặc đẹp”. Ẩm thực đã không còn đơn thuần là giá trị vật chất, mà còn là yếu tố văn hóa, một văn hóa đậm đà, duyên dáng và cốt cách.
                </p>
                <p className="fst-italic">
                  Không đứng ngoài tầm quan trọng của ẩm thực hiện đại của thế giới. Việt Nam ngày càng chú trọng là ăn uống sao cho bổ, rẻ, ngon mà vẫn đảm bảo vệ sinh để tự đảm bảo sức khoẻ cho chính mình.
                  Đồng hành với sự phát triển của nển Ẩm Thực Hiện Đại chúng tôi đặc biệt quan tâm tới nhu cầu "Ngon-Bổ-Rẻ" và "Thẩm Mỹ".
                </p>
                <ul>
                  <li><i className="bx bx-check-double"></i> Các món ăn là thông điệp mà vũ trụ nhắn gửi chúng tôi trao bạn.</li>
                  <li><i className="bx bx-check-double"></i> Vượt qua giới hạn về vật chất và chạm tới các giá trị tinh thần.</li>
                  <li><i className="bx bx-check-double"></i> Trong cuộc sống chúng ta tìm niềm vui. Trong từng khoảnh khắc chúng ta luôn kết nối. Chúng tôi vinh hạnh được hiện diện trong những khoảnh khắc để kết nối và chia sẻ tình yêu thương cùng bạn.</li>
                </ul>
                <p>
                  Thông điệp của vũ trụ bao la đã gửi tới chúng tôi văn hoá ẩm thực đương đại. Chúng tôi có sứ mệnh thiết kế và sáng tạo
                  các món ăn kèm những lời nhắn mà vũ trụ muốn trao gửi đến bạn qua thông điệp của chúng tôi.
                  Hãy đến với chúng tôi để biết rằng vũ trụ đã nhắn nhủ điều gì cho chúng ta nhé!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us">
      <div className="container">

        <div className="section-title">
          <h2>Tại sao nên chọn <span>Chúng Tôi</span></h2>
          <p>Không gian mở mang lại sự trải theo phong cách đa dạng. Thái độ tận tuỵ và nghiêm túc chăm sóc từng bữa ăn của bạn.</p>
        </div>

        <div className="row">

          <div className="col-lg-4">
            <div className="box">
              <span>01</span>
              <h4>Tận Tâm</h4>
              <p>Phục vụ vì sự hài lòng của khách hàng</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <span>02</span>
              <h4>Trách Nhiệm</h4>
              <p>Cung cấp sản  phẩm an toàn và chất lượng</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <span>03</span>
              <h4>Trung Thực</h4>
              <p>Cam kết quy trình  sản xuất đảm bảo vệ sinh an toàn thực phẩm</p>
            </div>
          </div>

        </div>

      </div>
    </section>
    </Fragment>
  );
};

export default AboutUs;
