import React, { Fragment } from 'react';
const NavBar = () => {
    return (
        <Fragment>

            {/* ======= Top Bar ======= */}
            <section id="topbar" className="d-flex align-items-center fixed-top topbar-transparent">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-center justify-content-lg-start">
                    <i className="bi bi-phone d-flex align-items-center"><span>+84-0906789789</span></i>
                    <i className="bi bi-clock ms-4 d-none d-lg-flex align-items-center"><span>Hàng Ngày: 06:00-10:00 & 16:00-22:00 PM</span></i>
                </div>
            </section>

            {/* ======= Header ======= */}
            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                    <div className="logo me-auto">
                        <h1><a href="/"><img src="assets/img/logo.png" alt="" className="img-fluid"/></a></h1>
                    </div>

                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li><a className="nav-link scrollto active" href="#hero">Trang Chủ</a></li>
                            <li><a className="nav-link scrollto" href="#about">Giới Thiệu</a></li>
                            <li><a className="nav-link scrollto" href="#menu">Thực Đơn</a></li>
                            <li><a className="nav-link scrollto" href="#events">Sự Kiện</a></li>
                            <li><a className="nav-link scrollto" href="#gallery">Hình Ảnh</a></li>
                            <li className="dropdown"><a href="/"><span>Đặt Món</span> <i className="bi bi-chevron-down"></i></a>
                                <ul>
                                    <li><a href="/">Phỏ Đặc  Biệt</a></li>
                                </ul>
                            </li>
                            <li><a className="nav-link scrollto" href="#contact">Liên Hệ</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>{/* .navbar */}
                    <a href="#book-a-table" className="book-a-table-btn scrollto">Đặt Bàn</a>
                </div>
            </header>{/* End Header */}
        </Fragment>
    );
};

export default NavBar;
