import React, { Fragment } from 'react';
import ReactDOM from "react-dom";

import { Banner, AboutUs, Menu, SpecialMenu, Gallery, FindUs, BookTable } from './container';
import { Navbar, Event, Footer } from './components';

ReactDOM.render(
  <Fragment>
    <Navbar />
    <Banner />
    <main id="main">
      <AboutUs />
      <Menu />
      <Event />
      <BookTable />
      <Gallery />
      <FindUs />
    </main>
    <Footer />
  </Fragment>, document.getElementById("root")
);
