import React, { Fragment } from 'react';
const BookTable = () => {
    return (
        <Fragment>
            <section id="book-a-table" class="book-a-table">
                <div class="container">

                    <div class="section-title">
                        <h2><span>Đặt Bàn</span></h2>
                        <p>Đặt bàn ngay để chúng tôi được phục vụ bạn tốt hơn.</p>
                    </div>

                    <form action="forms/book-a-table.php" method="post" role="form" class="php-email-form">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 form-group">
                                <input type="text" name="name" class="form-control" id="name" placeholder="Tên Của Bạn" data-rule="minlen:2" data-msg="Vui lòng cho tôi biết tên của bạn - tối thiểu 2 ký tự" />
                                <div class="validate"></div>
                            </div>
                            <div class="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                                <input type="email" class="form-control" name="email" id="email" placeholder="Địa Chỉ Email" data-rule="email" data-msg="Vui lòng nhập địa chỉ Email hợp lệ" />
                                <div class="validate"></div>
                            </div>
                            <div class="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                                <input type="text" class="form-control" name="phone" id="phone" placeholder="Số Điện Thoại" data-rule="minlen:10" data-msg="Số điện thoại tối thiểu 10 ký tự số" />
                                <div class="validate"></div>
                            </div>
                            <div class="col-lg-4 col-md-6 form-group mt-3">
                                <input type="text" name="date" class="form-control" id="date" placeholder="Ngày" data-rule="minlen:4" data-msg="Ngày nhập không đúng định dạng" />
                                <div class="validate"></div>
                            </div>
                            <div class="col-lg-4 col-md-6 form-group mt-3">
                                <input type="text" class="form-control" name="time" id="time" placeholder="Giờ" data-rule="minlen:4" data-msg="Thời gian không đúng định dạng" />
                                <div class="validate"></div>
                            </div>
                            <div class="col-lg-4 col-md-6 form-group mt-3">
                                <input type="number" class="form-control" name="people" id="people" placeholder="# Số Người Tham Dự" data-rule="minlen:1" data-msg="Vui lòng nhập tối thiểu 1 ký tự" />
                                <div class="validate"></div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <textarea class="form-control" name="message" rows="5" placeholder="Yêu cầu của bạn"></textarea>
                            <div class="validate"></div>
                        </div>
                        <div class="mb-3">
                            <div class="loading">Vui Lòng Đợi Chút Nhé</div>
                            <div class="error-message"></div>
                            <div class="sent-message">Yêu cầu của bạn đã được gửi thành công. Chúng tôi sẽ gọi điện hoặc gửi Email cho bạn để xác nhận thông tin. Thank you!</div>
                        </div>
                        <div class="text-center"><button type="submit">Gửi Yêu Cầu</button></div>
                    </form>
                </div>
            </section>
        </Fragment>
    );
};

export default BookTable;
